///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 21px 55px 21px 31px;
  max-width: 100%;
  color: $keyColor;
  background-color: #fff;
  line-height: 1.25;
  border: 1px solid $keyColor;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
              box-shadow: $box-shadow;
  @include mq-max(xs) {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: $white;
    background-color: $keyColor;
    &:after {
      right: 35px;
    }
  }
  &:after {
    @include fa('f054');
    position: absolute;
    top: 52%;
    right: 41px;
    transform: translateY(-50%);
    transition: right $transition;
  }

  //角丸ボタン
  &--round {
    background-color: $keyColor;
    color: $white;
    border-radius: 30px;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.5);
    transition: $transition;
    &:hover {
      box-shadow: none;
      transform: translateY(3px);
    }
  }

  //PDF
  &[href$=".pdf"] {
    &:after {
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $keyColor;
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //テキスト量で長さ可変
  &__inline {
    display: inline-block;
    width: auto;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &__small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //ホバーで背景がスライド
  &__slide {
    position: relative;
    overflow: hidden;
    transition: all .4s ease;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $keyColor;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) .4s;
    }
    &:hover {
      background-color: #fff;
      color: #fff;
      &:before {
        transform: translateX(0%);
      }
    }
    &>span {
      position: relative;
      z-index: 1;
    }
  }
}


//サイドボタン(共通)
.c-sideBtn {
  border: 3px $white solid;
  position: fixed;
  // top: 55%;
  right: 30px;
  bottom: 7%;
  // transform: translateY(-50%);
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: $keyColor;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: $white;
  transition: $transition;
  &:hover {
    opacity: .5;
  }

  // transition: all 0.2s ease;
  @include mq-max(md) {
    position: fixed;
    right: 0px;
    bottom: 0%;
    width: 100px;
    z-index: 1000;
    // border-radius: 0;
    // border: initial;
    height: 100px;
  }

  a {
    color: $white;
    font-size: 18px;
    font-weight: bold;
    @include mq-max(md) {
      font-size: 14px;
      line-height: 2;
    }
    &::before {
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      margin: 0 auto;
      background-image: url('/inc/image/common/ico_reserve.png');
      background-repeat: no-repeat;
      @include mq-max(md) {
        // margin: 0;
        width: 30px;
        height: 30px;
        background-size: contain;
      }
    }
    // @include mq-max(md) {
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
  }

  &:hover {
    // right: 0;
    opacity: 0.7;
    transition: all 0.2s ease;
  }

  //&__list {
  //  @include mq-max(sm) {
  //    position: relative;
  //  }
  //}

  //&__item {
  //  position: relative;
  //  right: 0px;

  //  &:last-child {
  //    border-bottom: none;
  //  }

  //  @include mq-max(sm) {
  //    border-bottom: none;
  //    bottom: 0px;

  //    &:last-child {
  //      border-right: none;
  //    }
  //  }

  //  @include mq-max(sm) {
  //    position: relative;
  //    right: 0;
  //    // width: 50%;
  //  }
  //}
}
