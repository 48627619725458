//ページネーション
.pagination {
  text-align: center;
  padding-bottom: 45px;
  @include mq-max(sm) {
    padding-bottom: 80px;
  }
  &__box {
    display: inline-block;
  }
  ul.page-numbers {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16 );
    overflow: hidden;
    li {
      margin-right: 0;
      font-size: 18px;
      font-weight: 500;
      border-right: 1px solid $gray;
      &:last-child {
        margin-right: 0;
        border-right: none;
      }
      .page-numbers {
        padding: 18.5px 26.9px;
        display: inline-block;
        color: $textColor;
        transition: all .4s ease;
        @include mq-max(xs) {
          padding: 6px 15px;
        }&.current {
          color: $white;
          background-color: $keyColor;
        }
        &:hover {
          color: $white;
          background-color: $keyColor;
        }
      }
    }
  }
}