  //代表挨拶
  .l-greet {
    &__catch {
      margin-bottom: 30px;

      strong {
        line-height: 1.5;
        font-size: 28px;
        font-weight: 500;
      }
    }

    &__txt {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__author {
      text-align: right;
      font-weight: 600;
      line-height: 1.3;
      margin-top: 25px;

      &-job {
        font-size: 16px;
        display: block;
        padding-right: 28px;
      }

      &-name {
        font-size: 22px;
        display: block;
      }
    }
  }