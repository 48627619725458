//予約CVエリア
.l-reserveCV {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    // background-color: #242424;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .4;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: calc(100% - (10px * 2));
    height: calc(100% - (15px * 2));
    // border: 1px solid $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &__bg {
    background-position: center;
    background-position-y: -15px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s cubic-bezier(0, -0.075, 0.27, 0.94);
    z-index: -1;
  }

  &>a {
    display: block;

    &:hover {
      .l-reserveCV__bg {
        transform: scale(1.1);
      }

      .l-reserveCV__more {
        &>span {
          &:after {
            right: -1em;
          }
        }
      }
    }
  }

  &__inner {
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 120px 15px 131px;
    position: relative;
    z-index: 2;

    @include mq-max(sm) {
      padding: 120px 30px 131px;
    }
  }

  &__desc {
    text-align: center;
    color: $white;
    margin-bottom: 35px;
    font-size: 15px;

    @include mq-max(xs) {
      text-align: left;
    }
  }

  &__more {
    text-align: center;
    color: $white;
    // margin: 0;
    font-size: 12px;
    line-height: 1;

    &>span {
      position: relative;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;


    }
  }

  //ボタン
  &__btn {
    border-radius: 50px;
    font-size: 18px;
    padding: 30px 55px 30px 70px;
    &::after {
      right: 38px;
    }
    &::before {
      font-family: $FontAwesome;
      font-size: 30px;
      padding-right: 5px;
      position: absolute;
      top: 50%;
      left: 35px;
      transform: translateY(-50%);
    }
    //&Box {
    //  display: inline-block;
    //}
    &--contact {
      &::before {
        content: '\f0e0';
      }
    }
    &--reserve {
      &::before {
        content: '\f236';
        font-size: 28px;
        left: 30px;
      }
    }
  }

  //パーツ調整
  .c-ttl-2 {
    color: $white;

    &>small {
      color: $white;
    }
  }

  .p-bar-position {
    display: block;
    line-height: 10px;
    max-width: 48px;
    margin: 0 auto;
    padding-top: 15px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }
}