//サイトレイアウト構造を記述

#container {}

#main {}

#contents {
  padding: 60px 0;

  @include mq-max(content) {
    padding: 60px 0 0;
  }

  @include mq-max(sm) {
    width: $full-size;
    padding: 30px 0 0;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}

[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;

  @include mq-max(content) {
    padding: 60px $contentPadding $sectionPadding;
  }

  @include mq-max(sm) {
    padding: 60px 30px 60px; //お好みで変更
  }

  @include mq-max(xs) {
    padding: 60px 15px 60px; //お好みで変更
  }

  &__inner {
    padding: 0 0 $sectionInnerPadding; //お好みで変更

    &:last-child {
      padding-bottom: 0;
    }
  }

  //横幅調整用
  &--full {
    max-width: 100%;
  }

  &--md {
    max-width: 990px;
    margin: 0 auto;

    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  &--sm {
    max-width: 800px;
    margin: 0 auto;

    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  &--xs {
    max-width: 600px;
    margin: 0 auto;

    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  // [class*="grid"] {
  //   margin-bottom: -1 * $contentPadding;
  // }

  &--lightgray {
    background-color: $lightGray;
    padding: 120px 0;
    margin-top: 60px;
    margin-bottom: 60px;

    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 60px $contentPadding;
      margin-top: 0;
    }

    @include mq-max(sm) {
      padding: 60px $contentPadding;
    }

    @include mq-max(xs) {
      padding: 60px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .l-section {
      &:last-child {
        @include mq-max(content) {
          padding-bottom: 0;
        }
      }
    }

    &--last {
      margin-bottom: 0;
    }

    .l-section {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--lightBlue {
    background-color: $lightBlue;
    padding: 60px 0;
    margin-top: 60px;
    margin-bottom: 60px;

    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 120px $contentPadding;
      margin-top: 0;
    }

    @include mq-max(sm) {
      padding: 60px $contentPadding;
    }

    @include mq-max(xs) {
      padding: 60px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .l-section {
      &:last-child {
        @include mq-max(content) {
          padding-bottom: 0;
        }
      }
    }

    &--band {
      background: linear-gradient(to right, $lightBlue calc(100% - 73px), #fff calc(100% - 73px));
      padding-top: 60px;
      padding-bottom: 60px;

      @include mq-max(bp1350) {
        padding-right: 73px + 30px;
        padding-left: 30px;
      }

      @include mq-max(content) {
        margin-bottom: 80px !important;
      }

      @include mq-max(xs) {
        background: linear-gradient(to right, $lightBlue calc(100% - 30px), #fff calc(100% - 30px));
        padding-left: 0;
        padding-right: 45px;
      }

      &:last-child {
        margin-bottom: 60px;
      }

      .l-section {
        padding-top: 0;

        &:last-child {
          padding-bottom: 0;
        }

        @include mq-max(content) {
          padding: 0 $contentPadding 80px;
        }

        @include mq-max(sm) {
          padding: 0 $contentPadding/2 80px; //お好みで変更
        }
      }
    }
  }

  &--lowPadding {
    padding: 60px 0;

    .l-section {
      padding-top: 0;

      &:last-child {
        padding-bottom: 0;
      }

      @include mq-max(content) {
        padding: 0 $contentPadding 80px;
      }

      @include mq-max(sm) {
        padding: 0 $contentPadding/2 80px; //お好みで変更
      }
    }
  }
}

//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
$breakpoint-value : xs,
sm,
md,
lg;

.u-taL {
  text-align: left !important;
}

.u-taC {
  text-align: center !important;

  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}

.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;

  @include mq-max(xs) {
    display: block !important;
  }
}

.u-only-sm {
  display: none !important;

  @include mq-max(sm) {
    display: block !important;
  }

  @include mq-max(xs) {
    display: none !important;
  }
}

.u-only-md {
  display: none !important;

  @include mq-max(md) {
    display: block !important;
  }

  @include mq-max(sm) {
    display: none !important;
  }
}

.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-not-under-sm {
  @include mq-max(sm) {
    display: none;
  }
}

.u-only-sm-xs {
  display: none;

  @include mq-max(sm) {
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {

  //  margin-bottom: 0 + (-1 * $contentPadding);
  &__wrap {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


//2カラムレイアウト
.l-col2 {
  position: relative;

  //画像端付き
  &--side {
    overflow: hidden;

    .l-col2__img {
      width: calc(100% / 2 - 22px);
      height: 510px;
      flex: 0 0 calc(100% / 2 - 22px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include mq-max(sm) {
        flex: initial;
        width: 100%;
        height: 300px;
      }
    }

    .l-col2__detail {
      padding: 0 0 0 50px;
      max-width: 552px;
      width: calc(100% / 2 + 22px);
      flex: 0 0 calc(100% / 2 + 22px);
      position: relative;

      @include mq-max(content) {
        padding-right: $contentPadding;
        padding-left: 30px;
      }

      @include mq-max(sm) {
        padding: 0 $contentPadding / 2;
        max-width: 100%;
        width: 100%;
      }
    }

    .l-col2__block {
      &--reverse {
        .l-col2__detail {
          padding: 0 50px 0 0;

          @include mq-max(content) {
            padding-right: 30px;
            padding-left: $contentPadding;
          }

          @include mq-max(sm) {
            padding-left: $contentPadding / 2;
          }
        }
      }
    }

    //パーツ調整
    .l-section {
      padding-left: 0;
      padding-right: 0;
    }
  }

  //画像番号付き
  &--ol {
    .l-section {
      counter-reset: listnum; /* カウンターをリセット */
      }

    .l-col2__block {
      margin-bottom: 80px;
      @include mq-max(xs) {
        margin-bottom: 30px;
      }
    }
    .l-col2__img {
      margin-bottom: 15px;
      max-height: 430px;
      border-radius: 30px;
      overflow: hidden;
      position: relative;
      counter-increment:ranking;
      width: 60%;
      @include mq-max(sm) {
        width: 100%;
      }
      &::before {
        content: "";
        top: 0;
        left: 0;
        border-bottom: 7em solid transparent;
        border-left: 7em solid $keyColor; /* ラベルの色はここで変更 */
        position: absolute;
        z-index: 100;
      }
      &::after {
        counter-increment: listnum; /* counter-resetと同じ文字列 */
        content: "0"counter(listnum);
        //content: "New!";
        display: block;
        top: 10px;
        color: $white; /* 文字色はここで変更 */
        font-weight: bold;
        font-size: 30px;
        left: 20px;
        position: absolute;
        z-index: 101;
      }
      &>img {
        width: 100%;
      }
    }
    .l-col2__detail {
      width: 40%;
      @include mq-max(sm) {
        width: 100%;
      }
    }
    //reverse設定
    .l-col2__block--reverse {
      .l-col2__img {
        &::before {
          border-left: initial;
          right: 0;
          border-right: 7em solid #FF7001;
        }
        &::after {
          left: initial;
          right: 20px;
        }
      }
    }
  }

  &__img {
    width: 50%;

    @include mq-max(sm) {
      width: 100%;
      // margin-bottom: 30px;
    }

    img {
      display: block;
      width: 85%;
      margin: 0 auto;

      @include mq-max(sm) {
        width: 60%;
      }
    }
  }

  &__detail {
    padding: 0 0 0 50px;
    width: 50%;
    position: relative;

    @include mq-max(sm) {
      width: 100%;
      padding: 0;
    }

    // &--img { //背景画像がある場合
    //   &:before {
    //     content: '';
    //     display: block;
    //     background: url('/inc/image/top/img_shovel.jpg') no-repeat center / contain;
    //     width: 940px;
    //     height: 511px;
    //     position: absolute;
    //     top: 50%;
    //     left: 380px;
    //     transform: translateY(-50%);
    //     z-index: -1;
    //     @include mq-max(md) {
    //       left: 50px;
    //     }
    //     @include mq-max(sm) {
    //       left: 80px;
    //       width: 940px * 0.7;
    //       height: 511px * 0.7;
    //     }
    //     @include mq-max(xs) {
    //       left: 80px;
    //       width: 940px * 0.5;
    //       height: 511px * 0.5;
    //     }
    //   }
    // }
  }

  &__block {
    display: flex;
    align-items: center;

    // padding-bottom: 120px;
    @include mq-max(sm) {
      flex-direction: column;
      padding-bottom: 30px;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &--reverse {
      flex-direction: row-reverse;

      @include mq-max(sm) {
        flex-direction: column;
      }

      .l-col2__detail {
        padding: 0 50px 0 0;
        position: relative;

        @include mq-max(sm) {
          padding: 0;
        }

        // &--img { //背景画像がある場合
        //   &:before {
        //     content: '';
        //     display: block;
        //     background: url('/inc/image/top/img_loader.jpg')no-repeat center / contain;
        //     width: 968px;
        //     height: 519px;
        //     position: absolute;
        //     top: 50%;
        //     right: 0;
        //     left: unset;
        //     transform: translateY(-50%);
        //     z-index: -1;
        //     @include mq-max(sm) {
        //       width: 940px * 0.7;
        //       height: 511px * 0.7;
        //     }
        //     @include mq-max(xs) {
        //       width: 968px * 0.5;
        //       height: 519px * 0.5;
        //     }
        //   }
        // }

      }
    }
  }

  &__btn {
    @include mq-max(sm) {
      text-align: center;
    }
  }
}

// 対応エリア
.l-network {
  position: relative;

  &__textarea {
    position: absolute;
    top: 220px;
    left: 0;
    width: 554px;

    @include mq-max(lg) {
      padding: 0 15px;
      top: 100px;
    }

    @include mq-max(md) {
      width: auto;
      position: static;
    }
  }

  &__imgarea {
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}

//章チャプター
.l-chapter {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}