  //企業理念
  .l-philosophy {
    &__inner {
      display: flex;
      align-items: center;

      @include mq-max(bp700) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__desc {
      padding-left: 90px;
      position: relative;
      top: 14px;

      @include mq-max(bp700) {
        padding-left: 0;
        top: 0;
      }

      strong {
        font-size: 24px;
        font-weight: 500;
        margin: 0;

        @include mq-max(xs) {
          font-size: 20px;
        }
      }
    }

    //パーツ調整
    .c-ttl-2 {
      margin: 0;

      @include mq-max(bp700) {
        margin-bottom: 10px;
      }
    }
  }