//リンク
.c-link {
  font-size: 12px;
  font-weight: 600;
  &--underline {
    border-bottom: 1px solid $keyColor;
  }
  &--arrow {
    display: inline-block;
    color: $keyColor;
    position: relative;
    line-height: 1;
    &:after {
      @include fa('f105');
      position: absolute;
      top: 55%;
      right: -8px;
      transform: translateY(-50%);
      line-height: 1;
      transition: all $transition;
    }
    &:hover {
      &:after {
        right: -10px;
      }
    }
  }
}

//ローカルリンク
.c-localLink {
  // &__item {}
  &__inner {
    display: flex;
    width: 100%;
    box-shadow: $box-shadow;
    transition: all $transition !important;
    &:hover {
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
      .c-localLink__title {
        color: $textColor;
      }
      .c-link {
        &:after {
          right: -1em;
        }
      }
    }
  }
  &__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    background: linear-gradient(#fff 0%, #ededed 100%);
    position: relative;
    &:before {
      content: '';
      width: 112px;
      height: 112px;
      background-color: $white;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include mq-max(sm) {
        width: 60px;
        height: 60px;
      }
    }
    @include mq-max(sm) {
      width: 80px;
    }
    i {
      font-size: 42px;
      color: $keyColor;
      line-height: 1;
      z-index: 2;
      @include mq-max(sm) {
        font-size: 30px;
      }
    }
  }
  &__body {
    width: calc(100% - 144px);
    padding: 43px 24px;
    background: $white;
    @include mq-max(sm) {
      width: calc(100% - 80px);
    }
  }
  &__title {
    margin-bottom: 0;
    font-size: 22px;
    color: $black;
    position: relative;
    @include mq-max(md) {
      font-size: 18px;
    }
    &--large {
      font-size: 24px;
      font-weight: 500;
      @include mq-max(sm) {
        font-size: 22px;
      }
    }

  }


  //パーツ調整
  .c-link {
    display: inline-block;
    line-height: 1;
    font-size: 12px;
  }


  //写真Ver
  &--pic {
    .c-localLink__thumb {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &:before {
        content: none;
      }
    }
    .c-localLink__title {
      display: inline-block;
      &:after {
        @include fa('f107');
        font-size: inherit;
        color: $keyColor;
        line-height: 1;
        position: absolute;
        top: 58%;
        right: -20px;
        transform: translateY(-50%);
      }
    }
  }

}