//ボックス
.c-box {
  background: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 30px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
  &--gray {
    background-color: $lightGray;
    border-color: $gray;
  }
  &--red {
    background-color: #FFF4F4;
    border-color: $accentColor;
  }

  &__heading {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0;
    margin-bottom: 2px;
  }
}

.c-whiteBox {
  padding: 40px 50px;
  background-color: $white;
  @include mq-max(xs) {
    padding: 30px 20px;
  }
}
//リスト＋画像
.c-listimgBox {
  padding: 60px 0;
  max-width: 100%;
  overflow: hidden;
  @include mq-max(md) {
    padding-top: 0;
    margin-bottom: 50px;
  }
  .grid {
    @include mq-max(md) {
      display: block;
    }
  }
  .col-6 {
    @include mq-max(md) {
      max-width: 100%;
    }
  }
  &__inner {

    .grid {
      flex-wrap: nowrap;
      @include mq-max(md) {
        flex-wrap: wrap;
      }
    }

    }
    &__txt {
      padding: 0;
      order: 1;
      &__inner {
        background-color: $lightBlue;
        max-width: 552px;
        // width: calc(100% / 2 + 22px);
        margin-left: auto;
        height: 100%;
        padding: 50px 30px 50px 50px;
        @include mq-max(md) {
          max-width: 100%;
          margin-left: 0;
          padding: 50px 90px;
        }
        @include mq-max(xs) {
          padding: 50px 50px;
        }
      }
    }
    &__img {
      order: 2;
      padding: 0;
      @include mq-max(md) {
        height: 300px;
      }

      &__inner {
        background-size: cover;
        background-position: center;
        height: 100%;
      }
    }
    // パーツ上書き
    .c-list__item {
      padding-left: 10px;
      position: relative;
      font-size: 20px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      @include mq-max(sm) {
        line-height: 1.5;
      }
      &:after {
        content: '';
        display: inline-block;
        border-bottom: 1px dashed $bdrColor;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: -20px;
      }
    }

}
