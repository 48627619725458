//画像
.c-img {
  &--round {
    border-radius: 30px;
    overflow: hidden;
    & img {
      width: 100%;
    }
  }
}
